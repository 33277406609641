import React from "react"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../components/layout"

const Cookies = ({ pageContext, location }) => {
  return (
    <Layout>
      <InnerHeader
        pageTitle="Cookies"
        pageContext={pageContext}
        location={location}
      />
      <div class="inner-container inner-y-padding">
        <h1>Cookies</h1>
        <h3>Use Of Cookies And Other Electronic Tools</h3>

        <p>
          We and/or our partners (e.g., service providers, business partners,
          advertisers and advertising servers) may place, view, and/or use
          cookies, Web server logs, Web beacons, or other electronic tools to
          collect statistical and other information about your use of our sites
          and other websites.
        </p>

        <p>
          To pursue the objectives described in this Privacy Policy, information
          collected about your use of our sites or other websites may be
          aggregated, analyzed or combined with personal or other information
          about you from other online or offline sources. If you prefer that we
          not combine the information that has been collected, through cookies,
          about your use of our sites or other websites with personal or other
          information about you, then please delete and disable cookies for each
          browser, on each computer you use to surf the Web.
        </p>

        <p>
          Most browsers are set to accept cookies by default. If you prefer, you
          can usually set your browser to disable cookies, or to alert you when
          cookies are being sent. Likewise, most mobiledevices allow you to
          disable the ability for geo-location information to be collected from
          your mobile device. The help function on most browsers and mobile
          devices contains instructions on how to set your browser to notify you
          before accepting cookies, disable cookies entirely, or disable the
          collection of geo-location data.
        </p>

        <p>
          However, if you disable cookies or refuse to accept a request to place
          a cookie, it is possible that some parts of our sites, such as the
          surveys, contests, and promotions, and certain areas of our sites for
          which you need to log in, will not function properly, and the
          advertising you receive when you visit our sites may not be
          advertisements tailored to your interests.{" "}
        </p>

        <h3>Third Party Cookies</h3>

        <p>
          In the course of serving advertisements to you in emails, third-party
          advertisers or ad servers may place or recognize a unique cookie on
          your browser. The use of cookies by such third party advertisers or ad
          servers is not subject to this Policy, but is subject to the privacy
          policies of those third parties.
        </p>
      </div>
    </Layout>
  )
}

export default Cookies
